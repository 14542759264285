import { LOCALE_TOGGLE } from './toggle';

export enum PublishedLocale {
  EnglishUnitedStates = 'en-US',
  EnglishAustralia = 'en-AU',
  EnglishUnitedKingdom = 'en-GB',
  EnglishCanada = 'en-CA',
  GermanGermany = 'de-DE',
  GermanAustria = 'de-AT',
}

export const localeUrlRegexMatcher = new RegExp(
  Object.values(PublishedLocale).join('|'),
  'i',
);

export const DEFAULT_LOCALE = PublishedLocale.EnglishUnitedStates;

export const isRouteSegmentLocale = (routeSegment: string) =>
  Boolean(routeSegment) &&
  Object.values(PublishedLocale).some(
    locale => locale.toLowerCase() === routeSegment.toLowerCase(),
  );

export const checkAndRemoveLocalePrefix = (path: string) => {
  const pathSegments = path.split('/');
  if (isRouteSegmentLocale(pathSegments[1])) {
    return '/' + pathSegments.slice(2).join('/');
  }
  return path;
};

export const isUrlStartsWithCurrentLocale = (url: string, currentLocale: string) => {
  const urlSegments = url.split('/');
  return urlSegments[1] === currentLocale;
};

export const addCurrentLocaleToUrl = (url: string, currentLocale: string) => {
  if (!LOCALE_TOGGLE) {
    return url;
  }
  if (isUrlStartsWithCurrentLocale(url, currentLocale)) {
    return url;
  }
  if (currentLocale === DEFAULT_LOCALE) {
    return url;
  }
  if (url === '/') {
    return `/${currentLocale}`;
  }
  return `/${currentLocale}${url}`;
};

export const getLocaleBasePath = (locale?: string) => {
  if (!locale || locale === DEFAULT_LOCALE) {
    return '';
  }
  return locale;
};

export const getCurrentRelativePathname = (locale?: string) => {
  if (typeof window !== 'undefined') {
    if (locale) {
      return window.location.pathname.replace(`/${locale}`, '');
    }
    return checkAndRemoveLocalePrefix(window.location.pathname);
  }
  console.warn('getCurrentRelativePathname is called on server side');
  return null;
};
